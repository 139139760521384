<template>
  <v-app>
    <div class="reserve-form">
      <h3 class="reserv-title">Password Reset Form</h3>
      <v-form ref="form" @submit="reset" v-model="valid" :lazy-validation="lazy" class="pa-3">
        <v-text-field
          v-model="form.password"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[v => !!v || 'Password is required',v => v.length >= 6 || 'Min 6 characters']"
          :type="show1 ? 'text' : 'password'"
          name="input-10-1"
          label="New Password"
          dense
          outlined
          @click:append="show1 = !show1"
        ></v-text-field>
        <v-text-field
          v-model="form.password_confirmation"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="conPassRules"
          :type="show2 ? 'text' : 'password'"
          name="input-10-1"
          label="Confirm New Password"
          outlined
          dense
          @click:append="show2 = !show2"
        ></v-text-field>
        <v-btn
          small
          block
          :disabled="!valid"
          style="color:#fff"
          color="deep-orange darken-2"
          @click="reset()"
          :loading="loading"
        >Reset Password</v-btn>
        <p class="float-left mt-2 f-z">
          <router-link :to="{name: 'signIn'}">
            or
            <span style="color:#e54c2a">Log In</span>
          </router-link>
        </p>
      </v-form>
      <notifications group="resetMail" position="top left" />
      <!-- snackbar -->
      <v-snackbar v-model="snackbar">
        {{ text }}
        <v-btn color="pink" text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
      <!-- snackbar -->
    </div>
  </v-app>
</template>

<script>
export default {
  name: "newPassword",
  data() {
    return {
      show1: false,
      show2: false,
      loading: false,
      lazy: false,
      text: "",
      valid: true,
      snackbar: false,
      form: {
        email: "",
        password: "",
        password_confirmation: "",
      },
      conPassRules: [
        (v) => !!v || "Name is required",
        () => this.form.password === this.form.password_confirmation || "Password must match",
      ],
      products: this.$store.state.cart,
    };
  },

  computed: {
    total: function () {
      return this.$store.state.cart.length;
    },
    passwordConfirmationRule() {
      return this.form.password === this.form.password_confirmation
    },
  },
  methods: {
    reset() {
      this.loading = true;
      let email = this.$route.query.email;
      let token = this.$route.params.token;
      this.axios
        .post("/password/reset", {
          email: email,
          token: token,
          password: this.form.password,
          password_confirmation: this.form.password_confirmation,
        })
        .then((response) => {
          let logRes = response.data.success;
          if (logRes == false) {
            this.loading = false;
            this.snackbar = true;
            this.text = response.data.message;
          } else {
            this.$notify({
              group: "resetMail",
              type: "success",
              title: response.data.message,
            });
          }
          this.loading = false;
        });
    },
  },
  mounted() {
    var el = document.querySelector(".v-application--wrap");
    el.classList.add("mystyle2");
  },
};
</script>

<style scoped>
.router-link-exact-active {
  color: #dbb664 !important;
}
.reserv-title {
  margin-top: 80px;
}
.theme--light.v-application {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.f-z {
  font-size: 14px;
}
</style>